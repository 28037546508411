<template>
    <div class="box_shadow" style="height:90%;width:100%;background:#fff;">
        <div class="inherit" style="height:65%;" >
            <img style="height:83%;width:80%;margin-top:5%" v-lazy="data&&require('../assets/'+data.url)||''">
        </div>
        <div class="flex column center" style="height:35%;padding:0 10% 0% 10%">
            <p class="column_title ellipsis1">{{data&&data.title}}</p>
            <p class="column_time ellipsis1" v-show="data&&data.time">{{data&&data.time}}</p>
            <p class="column_content ellipsis3">{{data&&data.content}}</p>
            <el-button type="primary" @click="go(data.path)" style="width:80%;margin-top:5px;margin-bottom:10px;">查看详情</el-button>
        </div>
    </div>
</template>
<script>
    export default {
        props:['data'],
        methods:{
            go(path){
                this.$store.commit('set_path',{path:path,query:{}})
            }
        }
    }
</script>
<style scoped>
.column_title{
    font-weight: bold;
    weight:90%;

}
.column_content{
    text-align:left;
    font-size:0.8rem;
    text-indent:2rem;
    letter-spacing:2px;
}
.column_time{
    align-self:flex-start;
    color:#b5b5b5;
    margin:5px 0;
}
</style>